import { IBizReportColumn } from '../IBizReport';
import { JournalEntriesDrilldown } from './journal-entries/journal-entries-drilldown';
import { OpenPostsDrilldown } from './open-posts/open-posts-drilldown';
import { SoldProductsDrilldownComponent } from './sold-products-drilldown/sold-products-drilldown.component';

export interface DrilldownData {
    colDef: IBizReportColumn;
    filterValues: { [name: string]: any };
    rowData: any;
}

export interface DrilldownPreviewConfig {
    component: any;
    params: any;
}

export interface ReportDrilldownConfig {
    previewComponent?: any;
    routeResolver: (drilldownData: DrilldownData) => { url: string; queryParams: any };
    preview?: (drilldownData: DrilldownData) => DrilldownPreviewConfig;
}

export const DRILLDOWN_CONFIGS: { [reportName: string]: ReportDrilldownConfig } = {
    Incomestatement: getTransqueryDrilldownConfig(),
    Balance: getTransqueryDrilldownConfig(),
    ReceivablesByAge: getOpenPostsConfig('ReceivablesByAge'),
    PayablesByAge: getOpenPostsConfig('PayablesByAge'),
    SoldProducts: getSoldProductsConfig(),
};

function getSoldProductsConfig() {
    return {
        routeResolver: (drilldownData: DrilldownData) => {
            return {
                url: `/sales/products/${drilldownData.rowData.ProductID}`,
                queryParams: {},
            };
        },
        preview: (drilldownData: DrilldownData) => {
            return {
                component: SoldProductsDrilldownComponent,
                params: {
                    productID: drilldownData.rowData.ProductID,
                    productNumber: drilldownData.rowData.ProductNumber,
                    productName: drilldownData.rowData.ProductName,
                    filterFromDate: drilldownData.filterValues.fromdate,
                    filterToDate: drilldownData.filterValues.todate,
                },
            };
        },
    };
}

function getOpenPostsConfig(reportName: 'ReceivablesByAge' | 'PayablesByAge') {
    return {
        routeResolver: (drilldownData: DrilldownData) => {
            return {
                url: '/accounting/postpost',
                queryParams: {
                    register: reportName === 'ReceivablesByAge' ? 'customer' : 'supplier',
                    number: drilldownData.rowData?.AccountNumber,
                },
            };
        },
        preview: (drilldownData: DrilldownData) => {
            return {
                component: OpenPostsDrilldown,
                params: {
                    number: drilldownData.rowData?.AccountNumber,
                    accountID: drilldownData.rowData?.AccountID,
                },
            };
        },
    };
}

function getTransqueryDrilldownConfig() {
    const getParams = (drilldownData: DrilldownData) => {
        const filterValues = drilldownData?.filterValues || {};
        const rowData = drilldownData?.rowData || {};

        let year = filterValues?.yr || new Date().getFullYear();
        let fromPeriod = filterValues.fp || 1;
        let toPeriod = filterValues.tp || 12;

        const columnName = drilldownData?.colDef?.Name || '';

        if (columnName.startsWith('Period')) {
            const period = parseInt(columnName.replace('Period', ''));
            if (period > 0) {
                fromPeriod = period;
                toPeriod = period;
            }
        }

        if (columnName === 'SumPeriodLastYear') {
            year = year - 1;
        }

        return {
            AccountNumber: rowData.AccountNumber,
            AccountName: rowData.AccountName,
            AccountYear: year,
            ProjectNumber: filterValues.fdim1 || filterValues.tdim1,
            DepartmentNumber: filterValues.fdim2 || filterValues.tdim2,
            Dimension5Number: filterValues.fdim5 || filterValues.tdim5,
            Dimension6Number: filterValues.fdim6 || filterValues.tdim6,
            Dimension7Number: filterValues.fdim7 || filterValues.tdim7,
            Dimension8Number: filterValues.fdim8 || filterValues.tdim8,
            Dimension9Number: filterValues.fdim9 || filterValues.tdim9,
            Dimension10Number: filterValues.fdim10 || filterValues.tdim10,
            FromPeriod: fromPeriod,
            ToPeriod: toPeriod,
        };
    };

    return {
        routeResolver: (drilldownData: DrilldownData) => {
            const params = getParams(drilldownData);
            return {
                url: '/accounting/transquery',
                queryParams: { reportDrilldown: true, ...params },
            };
        },
        preview: (drilldownData: DrilldownData) => {
            const params = getParams(drilldownData);
            return { component: JournalEntriesDrilldown, params };
        },
    };
}
